import React, { useState, useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Turn as Hamburger } from 'hamburger-react'
import './Navbar.css'

function Navbar() {
  const [menuIsOpen, setMenuIsOpen] = useState(false)
  
  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleToggle = () => {
    setMenuIsOpen(!menuIsOpen)
  }
  
  return (
    <div className='navbar'>
      <a href='/#homepage-section'>
        <div className='navbar__logo'>
          <img src='./img/LOGO POUCKE.png' alt='logo stoqup' />
        </div>
      </a>

      {
        isMobile ? 

        <div className='navbar__menu--mobile'>
          <Hamburger
            toggled={menuIsOpen} 
            toggle={setMenuIsOpen} 
            direction="left"
            color="#636C41"
            onToggle={handleToggle}
            className='hamburger'
          />
          <div className={`filling ${menuIsOpen ? "active" : ""}`}></div>
          <ul className={`menu__items ${menuIsOpen ? "active" : ""}`}>
            <li className={`menu__item ${menuIsOpen ? "active" : ""}`} onClick={ () => setMenuIsOpen(false) }><a href='#rural-fences-section'>Landelijke omheining</a></li>
            <li className={`menu__item ${menuIsOpen ? "active" : ""}`} onClick={ () => setMenuIsOpen(false) }><a href='#about-section'>Over ons</a></li>
            <li className={`menu__item ${menuIsOpen ? "active" : ""}`} onClick={ () => setMenuIsOpen(false) }><a href='#inspiration-section'>Inspiratie</a></li>
            <li className={`menu__item ${menuIsOpen ? "active" : ""}`} onClick={ () => setMenuIsOpen(false) }><a href='#contact-section'>contact</a></li>
          </ul>
        </div>

        :

        <div className='navbar__menu'>
        <ul>
          <li><a href='/#rural-fences-section'>Landelijke omheining</a></li>
          <li><a href='/#about-section'>Over ons</a></li>
          <li><a href='/#inspiration-section'>Inspiratie</a></li>
          <li><a href='/#contact-section'>contact</a></li>
        </ul>
      </div>

      }

    </div>
  )
}

export default Navbar
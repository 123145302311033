export const configuratorData = [
  // SECTION Materiaal 
  {
    "page": "material",
    "comesAfterChoice": "",
    "pageTitle": "Welk type materiaal?",
    "choices": [
      {
        "title": "Hout",
        "image": "wood.png"
      },
      {
        "title": "Metaal",
        "image": "steel.png"
      }
    ],
    "popupInfo": {
      "Hout": {
        "title": "Waarom kiezen voor hout?",
        "content": `
          <ul>
          <li>
            <b>Natuurlijke uitstraling</b>:<br />
            <p>
              Een houten omheining kan een warme, landelijke uitstraling geven en goed passen bij natuurlijke omgevingen.
            </p>
          </li>
          <li>
            <b>Veelzijdigheid</b>:<br />
            <p>
              Een houten omheining bestaat in vele vormen en ontwerpen. Het biedt een ruime keuze uit verschillende stijlen.
            </p>
          </li>
          <li>
            <b>Geluidsabsorptie</b>:<br />
            <p>
              Hout heeft van nature geluidsabsorberende eigenschappen, waardoor het geluid van bijvoorbeeld passerend verkeer of buren kan verminderen. Dit kan nuttig zijn als u privacy wilt of een rustige omgeving wilt creëren.
            </p>
          </li>
        </ul>
        `,
        "images": [
          "./img/assortment/thunder8.webp",
          "./img/assortment/thunder8.webp",
          "./img/assortment/special-heavy5.webp",
          "./img/assortment/special-heavy8.webp",
          "./img/assortment/safety-fence1.webp",
        ]
      },

      "Metaal": {
        "title": "Waarom kiezen voor metaal?",
        "content": `
          <ul>
          <li>
            <b>Duurzaamheid</b>:<br />
            <p>
              Metalen omheiningen zijn over het algemeen duurzamer en kunnen bestand zijn tegen verschillende weersomstandigheden zonder vervorming of rot.
            </p>
          </li>
          <li>
            <b>Lange levensduur</b>:<br />
            <p>
              Metalen omheiningen zijn over het algemeen zeer duurzaam en kunnen vele jaren meegaan zonder veel onderhoud. Ze zijn bestand tegen rot, insecten en andere schade die houten omheiningen kunnen aantasten.
            </p>
          </li>
          <li>
            <b>Moderne fijne look</b>:<br />
            <p>
              Metaal geeft door zijn stevigheid de mogelijkheid om voor een fijn & elegant ontwerp te kiezen. Wat een unieke en uiterst premium look geeft aan u tuin of weide.
            </p>
          </li>
        </ul>
        `,
        "images": [
          "./img/assortment/cortenstaal1.png",
        ]
      }

    }
  },
  // !SECTION

  // SECTION Metaal stijl
  {
    "page": "type",
    "comesAfterChoice": "Metaal",
    "pageTitle": "Welke stijl van metalen omheining?",
    "choices": [
      {
        "title": "Metaal type 1",
        "image": ""
      },
      {
        "title": "Metaal type 2",
        "image": ""
      }
    ],
    "popupInfo": {
      "Metaal type 1": {
        "title": "orem ipsum",
        "content": `
          <ul>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            orem ipsum            </p>
          </li>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            orem ipsum            </p>
          </li>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            lorem ipsum
            </p>
          </li>
        </ul>
        `,
        "images": [
        ]
      },

      "Metaal type 2": {
        "title": "orem ipsum",
        "content": `
          <ul>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            orem ipsum            </p>
          </li>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            orem ipsum            </p>
          </li>
          <li>
            <b>orem ipsum</b>:<br />
            <p>
            lorem ipsum
            </p>
          </li>
        </ul>
        `,
        "images": [
        ]
      }
    }
  },
  // !SECTION

  // SECTION Hout type
  {
    "page": "type",
    "comesAfterChoice": "Hout",
    "pageTitle": "Welk type van houten omheining?",
    "choices": [
      {
        "title": "Post & Rail",
        "image": "post_and_rail.png"
      },
      {
        "title": "Post & Wire",
        "image": "post_and_wire.png"
      },
      {
        "title": "Wire Fence",
        "image": "wire_fence.png"
      },
    ],
    "popupInfo": {
      "Post & Rail": {
        "title": "Post & Rail",
        "content": `
          <section>
            Een post & rail houten afsluiting is een eenvoudige en traditionele vorm van omheining, ook wel bekend als een houten railhekwerk. Het bestaat uit rechtopstaande palen (posten) die op gelijke afstanden van elkaar in de grond worden geplaatst, met daartussen horizontale liggers (rails) die aan de palen zijn bevestigd. Dit creëert een stevige en open structuur.
          </section>
          <ul>
          <li>
            <b>Natuurlijke uitstraling</b>:<br />
            <p>Het gebruik van hout geeft een natuurlijke en rustieke uitstraling aan de omgeving. Het past goed in landelijke en groene gebieden.</p>
          </li>
          <li>
            <b>Veiligheid voor dieren</b>:<br />
            <p>Het is een ideale keuze om dieren binnen te houden, zoals paarden, koeien of andere grote viervoeters, omdat het een robuuste omheining is die hen binnen de grenzen houdt met minimale mogelijkheid op kwetsuren.</p>
          </li>
        </ul>
        `,
        "images": [
          "./img/assortment/thunder1.webp",
          "./img/assortment/special-heavy5.webp",
          "./img/assortment/thunder2.webp",
          "./img/assortment/thunder6.webp",
          "./img/assortment/special-heavy6.webp",
          "./img/assortment/thunder7.webp",
          "./img/assortment/thunder8.webp",
          "./img/assortment/special-heavy8.webp",
        ]
      },

      "Post & Wire": {
        "title": "Post & Wire",
        "content": `
        <section>
          Een 'Post & Wire' omheining is een type landelijke omheining dat bestaat uit houten palen met elektrische draad gespannen tussen de palen. Deze omheining wordt vaak gebruikt voor het inperken van vee of andere dieren op landbouwbedrijven en landelijke eigendommen.
        </section>
          <ul>
          <li>
            <b>Kostenbesparend</b>:<br />
            <p>In vergelijking met sommige andere soorten omheiningen kan een Post & Wire omheining kosteneffectief zijn, vooral bij het omheinen van grote percelen.</p>
          </li>
          <li>
            <b>Eén met de omgeving</b>:<br />
            <p>Als je een landelijke omgeving wilt behouden en niet wilt dat de omheining het uitzicht verstoort, kan deze omheining een goede keuze zijn.</p>
          </li>
        </ul>
        `,
        "images": [
        ]
      },

      "Wire Fence": {
        "title": "Wire Fence",
        "content": `
        <section>
          Een 'Wire Fence' is een type landelijke omheining dat bestaat uit houten palen en metalen gaasdraad, ook wel bekend als een draadhekwerk. Dit type omheining wordt veel gebruikt op landelijke eigendommen, boerderijen en tuinen vanwege de duurzaamheid en effectiviteit ervan.
        </section>
          <ul>
          <li>
            <b>Zichtbaarheid</b>:<br />
            <p>In vergelijking met massievere omheiningen, zoals een massieve houten omheining, biedt een Wire Fence meer zichtbaarheid, waardoor je het zicht op de omgeving behoudt.</p>
          </li>
          <li>
            <b>Veelzijdigheid</b>:<br />
            <p>Deze omheining kan voor verschillende doeleinden worden gebruikt, zoals het omheinen van tuinen, het beschermen van landbouwgewassen of het creëren van dierenverblijven.</p>
          </li>
          <li>
            <b>Voor kleinere dieren</b>:<br />
            <p>
              Een Wire Fence is ideaal voor het binnenhouden of buitenhouden van kleinere diersoorten zoals schapen, kippen, honden, ...
            </p>
          </li>
        </ul>
        `,
        "images": [
        ]
      },
    }
  },
  // !SECTION

    // SECTION Hout stijl
    {
      "page": "style",
      "comesAfterChoice": "Post & Rail",
      "pageTitle": "Welke stijl van Post & Rail?",
      "choices": [
        {
          "title": "Special Heavy",
          "image": "special-heavy5.webp"
        },
        {
          "title": "Thunder",
          "image": "thunder1.webp"
        },
        {
          "title": "Ashford",
          "image": "ashford.webp"
        },
      ],
      "popupInfo": {
        "Special Heavy": {
          "title": "Special Heavy",
          "content": `
            <section>
              Special Heavy is een unieke omheining.<br/><br/> 

              Het heeft een natuurlijke en rustieke uitstraling. Het is de ideale keuze voor wie een stevige omheining wil dat één is met de natuur.<br/><br/>

              Deze afsluiting bestaat uit geïmpregneerd hout en biedt een garantie van 25 jaar.
            </section>
          `,
          "images": [
            "./img/assortment/special-heavy5.webp",
            "./img/assortment/special-heavy6.webp",
            "./img/assortment/special-heavy8.webp",
          ]
        },
  
        "Thunder": {
          "title": "Thunder",
          "content": `
            <section>
              Onze Post & Rail afsluiting type 'Thunder' is een populaire optie voor paardeliefhebbers en heeft een elegant landelijke uitstraling. De 'Thunder' afsluiting bestaat uit houten palen met diamantkop en 3 horizontale liggers door de palen geschoven.<br/></br>

              Deze afsluiting bestaat standaard uit geïmpregneerd naaldhout en biedt een garantie van 25 jaar. Maar is ook ter beschikking in Tropisch Hardhout voor ultieme stevigheid en kwaliteit.
            </section>
          `,
          "images": [
            "./img/assortment/thunder1.webp",
            "./img/assortment/thunder2.webp",
            "./img/assortment/thunder6.webp",
            "./img/assortment/thunder7.webp",
            "./img/assortment/thunder8.webp",
          ]
        },
  
        "Ashford": {
          "title": "Ashford",
          "content": `
            <section>
            Onze Post & Rail afsluiting type 'Ashford' is een klassieker en bestaat uit houten palen met 3 horizontale ligger bovenop de palen.<br/></br>

            Deze afsluiting bestaat uit geïmpregneerd hout en biedt een garantie van 25 jaar.
            </section>
          `,
          "images": [
          ]
        },
      }
    },
    // !SECTION
]

import React, { useState, useEffect } from 'react'
import './PhotoPopupScreen.css'

export function closeImagePopup(cleanUp) {
  const popup = document.querySelector('#photo-popup-screen')
  cleanUp("")
}

export function PhotoPopupScreen(props) {

  const imageLink = require(`../assets/assortment/${props.image}`)
  console.log(props.image)

  return (
    <div className='photo_popup_screen__overlay' id='photo-popup-screen'>
      <div className='photo_popup_screen__container'>
        <div className='close__button' onClick={() => closeImagePopup(props.cleanUp)}>X</div>
        <img src={imageLink}/>
      </div>
    </div>
  )
}

export default PhotoPopupScreen

import React, { useState, useEffect } from 'react'
import './ContactForm.css'
import axios from 'axios'
import LoadingSpinner from './LoadingSpinner'

function ContactForm() {
  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [subject, setSubject] = useState("")
  const [message, setMessage] = useState("")

  const handleSubmit = async (event) => {
    event.preventDefault()
    const LoadingSpinner = document.querySelector('.loading-spinner__wrapper')
    const notification_message = document.querySelector('.message__wrapper')
    const submitButton = document.querySelector('#submit-button')

    LoadingSpinner.style.display = 'block'
  
    const data = {
      name: name,
      email: email,
      subject: subject,
      message: message
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/sendmail`, data)
      console.log(response)
  
      if(response.status != 200) {
        LoadingSpinner.style.display = 'none'
        notification_message.innerHTML = 'Er is iets misgelopen tijdens het verzenden, probeer later opnieuw'
        notification_message.style.display = 'block'
        return 
      }
    }
    catch (error) {
      console.log(error)
      LoadingSpinner.style.display = 'none'
      notification_message.innerHTML = 'Er is iets misgelopen tijdens het verzenden, probeer later opnieuw'
      notification_message.style.display = 'block'
    }

    setName("")
    setEmail("")
    setSubject("")
    setMessage("")
    
    LoadingSpinner.style.display = 'none'
    notification_message.innerHTML = 'Bedankt voor uw bericht! We nemen zo snel mogelijk contact met u op.'
    notification_message.style.display = 'block '
  }


  return (
    <form className='contact_form' onSubmit={handleSubmit} >

      <input type='text' id='name' name='name' placeholder='naam' value={name} onChange={(event) => setName(event.target.value)} />

      <input type='email' id='email' name='email' placeholder='email' value={email} onChange={(event) => setEmail(event.target.value)} />

      <input type='text' id='subject' name='subject' placeholder='onderwerp' value={subject} onChange={(event) => setSubject(event.target.value)} />

      <textarea id='message' name='message' placeholder='schrijf hier uw bericht' value={message} onChange={(event) => setMessage(event.target.value)} />

      <div className='submit__wrapper'>
        <input type='submit' className='submit-button' id='submit-button' />
        <div className='message__wrapper'></div>
        <div className='loading-spinner__wrapper'><LoadingSpinner/></div>
      </div>

    </form>
  )
}

export default ContactForm
import React, { useState, useEffect } from 'react'

function RotatingDiv() {
    const [scrollPosition, setScrollPosition] = useState(0)
    const [divPosition, setDivPosition] = useState(0)
  
    useEffect(() => {
      const handleScroll = () => {
        const currentPosition = window.pageYOffset
        setScrollPosition(currentPosition);
        setDivPosition(currentPosition * 0.9) // adjust the factor to control the speed of the animation
      };
  
      window.addEventListener('scroll', handleScroll)
  
      return () => {
        window.removeEventListener('scroll', handleScroll)
      };
    }, [])

  const styles = {
    transform: `translateX(${divPosition}px) rotate(-15deg)`,
    transition: 'transform 0.5s ease-out', // adjust the duration and easing to control the animation effect
  }

  return (
    <div className="rotating-div" style={styles}></div>
  )
}

export default RotatingDiv;
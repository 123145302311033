import React, { useState, useEffect } from 'react'
import './ChoiceElement.css'
import InfoIcon from '@mui/icons-material/Info'
import { useMediaQuery } from 'react-responsive'

function ChoiceElement({ index, title, setPopupActive, setselectedChoice, onClick, image }) {
  const [hovered, setHovered] = useState(false)

  const isMobile = useMediaQuery({ query: '(max-width: 768px)' })

  const handleMouseEnter = (event) => {
    setHovered(true)
  }

  const handleMouseOut = (event) => {
    setHovered(false)
  }

  const handleClickInfoItem = (event) => {
    event.stopPropagation()
    setselectedChoice(event.currentTarget.parentNode.getAttribute("data-choice"))
    setPopupActive(true)
  }

  // Check if the image ends with ".png" or ".webp"
  const isPng = image.endsWith('.png')
  const isWebp = image.endsWith('.webp')

  return (
    <div
      className='configurator__choice'
      id='wood'
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseOut}
      data-choice={title}
      onClick={(event) => onClick(event)}
    >
      <div className={`info-icon ${hovered & !isMobile ? "hovered" : ""}`} onClick={handleClickInfoItem}><InfoIcon /></div>
      {
        !hovered | isMobile ?
          <img className={`${ isWebp ? "photo" : ""}`} src={`./img/configurator/${image}`} /> :
          <div className={`choice-title ${hovered & !isMobile ? "hovered" : ""}`}>{title}</div>
      }
    </div>
  )
}

export default ChoiceElement
export const inspirationImages = [
  {
    title: 'Thunder',
    description: "<p>'Thunder', vernoemd naar 1 van onze eerste pony's als kind. Sterk, stevig & betrouwbaar! Thunder is een afsluiting in de categorie 'Post & Rail', beschikbaar met 3 liggers of 2 liggers en in verschillende hoogtes.<br/><br/><strong>Ideaal voor:</strong> Paarden | Alpaca's | & andere grote vier voeters </p>",
    images: [
      './img/assortment/thunder1.webp',
      './img/assortment/thunder2.webp',
      './img/assortment/thunder6.webp',
      './img/assortment/thunder7.webp',
      './img/assortment/thunder8.webp',
    ]
  },
  {
    title: 'Special Heavy',
    description: "<p>De 'special heavy' is een populaire omheining uit de categorie 'Post & Rail', het heeft een rustieke & elegante afwerking.<br/><br/><strong>Ideaal voor:</strong> Paarden | Alpaca's | & andere grote vier voeters </p>",
    images: [
      './img/assortment/special-heavy5.webp',
      './img/assortment/special-heavy6.webp',
      './img/assortment/special-heavy8.webp',
    ]
  },
  {
    title: 'Safety Fence',
    description: "<p>Safety Fence&reg is een innovatief alternatief op de 'Post & Rail'. Het is ontwikkeld voor extra veiligheid voor uw favoriete vier voeter. De linten bestaan uit zacht, buigbaar materiaal en voorkomen mogelijkse kwetsuren. Een bijkomend voordeel is de fijnere afwerking voor een subtiele aanwezigheid van de omheining.<br/><br/><strong>Ideaal voor:</strong> Padock | Stapmolens | Weides </p>",
    images: [
      './img/assortment/safety-fence1.webp',
    ]
  },
  {
    title: 'Wired Fence',
    description: "<p>Een Wired Fence bestaat uit een combinatie van houten palen en gaasdraad. Ideaal voor kleinere dieren, maar wordt ook veel gekozen voor de subtiele aanwezigheid.<br/><br/><strong>Ideaal voor:</strong> Kleine viervoeters | Kippen | Ganzen </p>",
    images: [
      './img/assortment/wired_fence4.webp',
      './img/assortment/wired_fence5.webp',
      './img/assortment/wired_fence8.webp',
      './img/assortment/wired_fence13.webp',
      './img/assortment/wired_fence14.webp',
    ]
  },
  {
    title: 'Ashford',
    description: "<p>Onze Ashford afsluiting is een afsluiting uit de categorie 'Post & Rail' bestaande uit geïmpregneerd naaldhout palen en planken. Bij een Ashford afsluiting worden de planken op de palen bevestigd in plaats van door de palen heen.<br/><br/><strong>Ideaal voor:</strong> Paarden | Alpaca's | & andere grote vier voeters </p>",
    images: [
      './img/assortment/ashford.webp',
      './img/assortment/ashford_2.webp',
      './img/assortment/ashford_3.webp',
      './img/assortment/ashford_5.webp',
      './img/assortment/ashford_9.webp',
    ]
  },
]

import React, { useState, useEffect } from 'react'
import './CookieNotification.css'

function CookieNotification() {
  const [cookieAccepted, setCookieAccepted] = useState(false)

  useEffect(() => {
    const isCookieAccepted = localStorage.getItem("cookieAccepted")
    if (isCookieAccepted == "true") {
      setCookieAccepted(true)
    }
  }, [])

  const handleOnClickAccept = () => {
    const cookieNotification = document.querySelector("#cookie_notification")
    cookieNotification.classList.add("hide")

    localStorage.setItem("cookieAccepted", "true")
    setCookieAccepted(true)
  }

  if (cookieAccepted) {
    return null
  }

  return (
    <div className='cookie_notification__container' id='cookie_notification'>
      <p>
        Deze website maakt gebruik van cookies om uw ervaring te verbeteren. Door deze site te gebruiken, stemt u in met ons gebruik van cookies. Meer informatie over ons cookiebeleid vindt u in onze <a href='/cookie-policy'>Cookieverklaring</a>.
      </p>
      <button onClick={handleOnClickAccept}>Accepteer</button>
    </div>
  )
}

export default CookieNotification
import React, { useRef, useEffect, useState } from 'react'
import './HomePage.css'
import RotatingDiv from '../components/RotatingDiv'
import AboutText from '../components/AboutText'
import ContactForm from '../components/ContactForm'
import PhotoPopupScreen from '../components/PhotoPopupScreen'
import Project from '../components/Project'
import Product from '../components/Product'
import { projects } from '../data/projectsData'
import { products } from '../data/productsData'
import { inspirationImages } from '../data/inspirationImages'
import CookieNotification from '../components/CookieNotification'

function HomePage() {
  const [selectedProduct, setSelectedProduct] = useState("Thunder")
  const [showImages, setShowImages] = useState(false)
  const [imageToShow, setImageToShow] = useState("")
  const [isImageLoaded, setIsImageLoaded] = useState(false)

  const imageRef = useRef(null)

  const revealHomePageText = () => {
    const homePageText = document.querySelector('#home-page-text')

    homePageText.children[0].classList.add('text-visible')
    setTimeout(() => {
      homePageText.children[1].classList.add('text-visible')
    }, 1000);
  }

  const handleImageLoad = () => {
    setIsImageLoaded(true);
  }

  useEffect(() => {

    const handleScroll = () => {
      const windowHeight = window.innerHeight

      // Handle animation About us images when visible 
      const imageElements = document.querySelectorAll('.image__fade_in--left, .image__fade_in--right')
      imageElements.forEach(imageElement => {

        const imageElementPosition = imageElement.getBoundingClientRect().top

        if (imageElementPosition < windowHeight) {
          imageElement.classList.add('visible')
        }
        if (imageElementPosition > windowHeight) {
          imageElement.classList.remove('visible')
        }

      })

    }
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }   
  }, [])

  useEffect(() => {
    revealHomePageText()
  }, [])

  useEffect(() => {
    setShowImages(false)
  }, [selectedProduct])

  useEffect(() => {
    if (!showImages) {
      // This delay gives time for the images to be re-rendered before triggering the transition
      setTimeout(() => {
        setShowImages(true)
      }, 500)
    }
  }, [showImages])

  const handleOnClickImage = (image) => {
    const popup = document.querySelector('#photo-popup-screen')
    const imageNameWebp = image.replace("./img/assortment/", "")
    const imageName = imageNameWebp.replace("webp", "jpg")

    setImageToShow(imageName)
  }

  return (
    <main>
      <CookieNotification/>
      {imageToShow != "" ? <PhotoPopupScreen image={imageToShow} cleanUp={setImageToShow}/> : null}

      <div className='homepage__section' id='homepage-section' >

        <img className='background_image' src='./img/backgroundimage.webp' onLoad={handleImageLoad} />

        <div className={`homepage__section__text ${isImageLoaded ? 'visible' : ''}`} id='home-page-text'>
          <div className='text__row-1'>De specialist in</div>
          <div className='text__row-2'>landelijke omheining</div>
        </div>

        <RotatingDiv />

      </div>

      <div className='rural_fences__section' id='rural-fences-section'>
        <h1>Landelijke omheiningen</h1>

        <section>
          <div className='paragraph'>Een landelijke omheining is niet alleen een functionele manier om uw woning af te bakenen, maar het kan ook bijdragen aan het landelijke karakter van uw huis. Hieronder leest u meer over de voordelen van een landelijke omheining, hoe het uw woning kan completeren en welke overwegingen u moet maken bij de aanschaf.</div>
          <div className='image_grid'>
            <img src='./img/rural_fences/rural-fence-house-poucke.png' alt='rural fence landelijke omheining' className='image__fade_in--left' ref={imageRef} />
            <img src='./img/rural_fences/rural-fence-horse.webp' alt='rural fence landelijke omheining' className='image__fade_in--right' ref={imageRef} />
          </div>
          <h3>Voordelen van een landelijke omheining</h3>
          <ul>
            <li>Een landelijke omheining is diervriendelijk omdat het geen scherpe randen of prikkeldraad bevat en dieren beschutting biedt.</li>
            <li>Het past perfect in het landschap en kan het landelijke karakter van uw huis versterken.</li>
            <li>Een landelijke omheining kan ook een toegevoegde waarde bieden aan uw eigendom.</li>
          </ul>
          <h3>Prijs van een landelijke omheining</h3>
          <div className='paragraph'>De prijs van een landelijke omheining is afhankelijk van verschillende factoren, zoals het type hout, de lengte van de omheining en de installatiekosten.</div>
        </section>

      </div>

      <div className='about__section' id='about-section'>
        <h1>Over ons</h1>

        <AboutText
          imageName='marc-machine'
          title='Familiebedrijf'
          text={
            <p>
              Poucke is een familie bedrijf met meer dan 20 jaar ervaring in het
              plaatsen van landelijke omheiningen. Of u nu op zoek bent naar een
              traditionele houten omheining of juist een meer moderne uitstraling
              wenst met een metalen omheining, wij hebben alles in huis om aan
              uw wensen te voldoen.

              <br /><br /> Wij helpen u graag bij het kiezen van de juiste omheining,
              <br /> Neem contact met ons op voor een vrijblijvende offerte.

            </p>
          }
        />

        <AboutText
          imageName='marc-paard'
          title='Ervaren paardenliefhebbers'
          text={
            <p>
              Als ervaren paardenliefhebbers begrijpen wij als geen ander hoe belangrijk de juiste
              omheining is voor uw paard of ander dier. Bij Poucke streven we altijd naar de beste
              kwaliteit en service voor onze klanten. Onze expertise en ervaring zorgen
              ervoor dat wij u kunnen adviseren over de beste oplossing voor uw specifieke situatie.
              Of het nu gaat om het plaatsen van een nieuwe omheining of het herstellen van een
              bestaande, bij Poucke bent u aan het juiste adres.
            </p>
          }
        />

      </div>

      <div className='inspiration__section' id='inspiration-section'>
        <h1>Inspiratie</h1>

        <div className='inspiration__wrapper'>
          <div className='inspiration__nav'>
            {
              inspirationImages.map((image, index) => {
                return (
                  <div className={`inspiration__nav_item ${selectedProduct == image.title ? "selected" : ""}`} onClick={() => setSelectedProduct(image.title)} key={index}>{image.title}</div>
                )
              })
            }
          </div>
          {
            inspirationImages
              .filter(item => item.title == selectedProduct)
              .map((item, index) => {
                const middlePoint = Math.ceil(item.images.length / 2)
                const column1 = item.images.slice(0, middlePoint)
                const column2 = item.images.slice(middlePoint)

                return (
                  <div className='inspiration__item__wrapper' key={index}>
                    <div className='inspiration__item__description' dangerouslySetInnerHTML={{__html: item.description}} >
                    </div>
                    <div className='inspiration__item__images'>
                      <div className='image__column1'>
                        {
                          column1.map((image, index) => {
                            return (
                              <img className={`inspiration__image ${ showImages ? "show" : "" }`} src={image} alt={item.title} key={index} onClick={() => handleOnClickImage(image)} />
                            )
                          })
                        }
                      </div>
                      <div className='image__column2'>
                        {
                          column2.map((image, index) => {
                            return (
                              <img className={`inspiration__image ${ showImages ? "show" : "" }`} src={image} alt={item.title} key={index} onClick={() => handleOnClickImage(image)} />
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                )
              })
          }
        </div>
      </div>

      {/* <div className='assortment__section' id='assortment-section'>
        <h1>Inspiratie</h1>
        <p className='sub-title-text'>Wij leveren en/of plaatsen een wijd aanbod aan landelijke omheiningen, poorten en toebehoren.<br /> Hieronder een aantal voorbeelden uit ons aanbod ter inspiratie voor uw project.</p>

        <div className='assortment__wrapper'>
          {
            products.map((product, index) => {
              return (
                <Product
                  key={index}
                  index={index}
                  title={product.title}
                  text={product.text}
                  img_src_array={product.img_src_array}
                  ideal_for={product.ideal_for}
                />
              )
            })
          }
        </div>
      </div>

      <div className='projects__section' id='projects-section'>
        <h1>Gerealiseerde projecten</h1>
        <div className='projects__wrapper'>
          {
            projects.map((project, index) => {
              return (
                <Project
                  key={index}
                  title={project.title}
                  text={project.text}
                  img_src={project.img_src}
                />
              )
            })
          }

        </div>
      </div> */}

      <div className='contact__section' id='contact-section'>
        <h1>Contacteer ons!</h1>
        <ContactForm />
      </div>

    </main>
  )
}

export default HomePage